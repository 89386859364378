.cc-switch {
  display: flex;
  align-items: center;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + &-slider {
    background-color: $color-green;
  }

  input:checked + &-slider:before {
    transform: translateX(22px);
  }

  &-label {
    @include font-size(16px);
    margin-left: 8px;
  }
}

.cc-switch-button {
  position: relative;
  display: block;
  width: 46px;
  height: 28px;
  flex-shrink: 0;
}

.cc-switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-gray-200;
  transition: 0.4s;
  border-radius: 20px;

  &:before {
    content: "";
    position: absolute;
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 5px;
    background-color: #fff;
    transition: 0.4s;
    border-radius: 50%;
    box-shadow: 0 3px 1px rgba($color-black, 0.1);
  }
}
