.breadcrumbs {
  display: flex;
  padding: 15px 15px;
  border-radius: 8px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    font-size: 12px;
  }

  a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s;
    font-size: 18px;
    font-weight: 600;

    @media (max-width: 768px) {
      font-size: 14px;
    }

    &:hover {
      color: #0056b3;
    }
  }

  span {
    color: #666;
  }

  .separator {
    margin: 0 8px;
    color: #ccc;
  }

  .link {
    display: flex;
    align-items: center;
    flex-direction: row;
    // background-color: red;

    @media (max-width: 768px) {
      max-width: 20%;
      font-size: 10px;
    }

    white-space: nowrap;
    overflow-x: scroll;
  }
}

.back {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: #007bff;
  }

  svg {
    height: 32px;
    width: 32px;
  }
}

.separator::before {
  content: ">";
  margin: 0 8px;
  color: #ccc;
}
