.cc-icon {
    display: block;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    user-select: none;
    fill: currentColor;
    width: 0.8em;
    height: 0.8em;

    &.cc--inline {
        display: inline-block;
    }
}