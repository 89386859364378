.cc-details {
  &-wrapper {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;

    h6 {
      font-family: $font-open-sans;
    }

    p {
      color: $color-gray-400;
      @include font-size(14px);
      line-height: 1.5;
    }
  }

  &-image {
    padding-bottom: 66%;
    position: relative;
    margin-bottom: 15px;

    img {
      border-radius: 5px;
      border: 1px $color-gray-400 solid;
    }
  }
}
