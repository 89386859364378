.container {
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 100vh;
}

.main {
  flex: 1;
}

.main-full-height {
  min-height: calc(100vh - 178px);
}

.footer {
  margin-top: 20px;
  width: 100%;
}
