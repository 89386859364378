.cc-position {
  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
  }

  &-wrapper {
    background-color: #fff;
    border: 1px $color-primary solid;
    border-radius: 10px;
    padding: 20px;

    &__heading {
      @include font-size(14px);
      line-height: 1.2;
      font-weight: bold;
      color: $color-gray-400;
    }
  }

  &-form {
    &__row {
      &:not(:last-child) {
        margin-bottom: 14px;
      }

      & .ant-form-item-control-input-content {
        display: flex;
        align-items: center;
      }
    }

    &__group {
      display: flex;

      .cc-position-input__wrapper {
        flex-basis: 0;
        flex-grow: 1;
      }
    }

    &__action {
      &-group {
        display: flex;
        justify-content: center;
        padding-top: 10px;
      }

      &-item {
        padding: 0 8px;
      }

      &-update {
        border-radius: 10px;

        & > span {
          padding: 9px 10px;
          @include font-size(14px);
          line-height: 1;
        }
      }
    }
  }

  &-label {
    width: 80px;
    flex-shrink: 0;
    color: $color-gray-400;
    text-transform: capitalize;
    @include font-size(14px);
    line-height: 1.2;
    font-weight: bold;
  }

  &-input {
    width: 100%;
    border: 1px $color-primary solid;
    font-family: $font-open-sans;
    @include font-size(12px);
    line-height: 1.2;
    border-radius: 5px;
    color: $color-gray-400;
    padding-left: 25px;
    padding-right: 8px;
    outline: 0;
    height: 26px;

    &:focus {
      border-color: $color-primary-dark;
    }

    &__wrapper {
      padding-left: 10px;
      position: relative;

      .ant-form-item-label {
        height: 26px;
        position: absolute;
        top: 0;
        left: 10px;
        color: $color-primary;
        @include font-size(14px);
        line-height: 1;
        border-right: 1px $color-primary solid;
        font-weight: bold;
        padding: 5px;
        pointer-events: none;
      }
    }
  }

  &-back__btn {
    border-radius: 5px;
    margin-right: 7px;

    & > span {
      padding: 2px;
      @include font-size(16px);
    }
  }
}
