html {
  box-sizing: border-box;
  font-size: $base-font-size;
  height: 100%;
  width: 100%;
}

body {
  width: 100%;
  height: 100%;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  color: $color-black;
  background-color: #fff;
  @include font-size(14px);
  font-family: $font-open-sans;
}

main {
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
}

* {
  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }
}

strong,
b {
  font-weight: bold;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

p {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 15px;
  font-family: $font-orbitron;
  font-weight: bold;
}

h1 {
  @include font-preset("h1");
}

h2 {
  @include font-preset("h2");
}

h3 {
  @include font-preset("h3");
}

h4 {
  @include font-preset("h4");
}

h5 {
  @include font-preset("h5");
}

h6 {
  @include font-preset("h6");
}

input::placeholder,
::placeholder {
  font-weight: normal;
}

//remove ie clear button from input
::-ms-clear {
  display: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
