.cc-object-item {
  background-color: #fff;
  border-radius: 8px;
  padding: 8px;
  align-items: center;
  cursor: pointer;
  transition: background-color 300ms ease;

  &.active {
    background-color: $color-blue-400;

    .cc-object-item__avatar {
      border-color: #fff;
    }

    .cc-object-item__name {
      color: #fff;
    }

    .cc-object-item__label {
      background-color: #fff;
    }
  }

  &:not(.active):hover {
    background-color: $color-blue-100;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &__avatar {
    width: 50px;
    height: 40px;
    border-radius: 5px;
    border: 1px $color-primary solid;
    overflow: hidden;
    margin-right: 10px;
    flex-shrink: 0;
    background-color: #fff;
  }

  &__name {
    @include font-size(16px);
    line-height: 1.2;
    flex-grow: 1;
    min-width: 0;
  }

  &__label {
    border: 1px $color-primary solid;
    border-radius: 10px;
    padding: 6px 10px;
    background-color: $color-blue-200;
    flex-shrink: 0;
    @include font-size(14px);
    line-height: 1.2;
    align-items: center;
    margin-left: 10px;
  }

  &__icon {
    margin-right: 3px;
    @include font-size(16px);
  }

  &__remove {
    border-radius: 5px;

    & > span {
      padding: 5px;
      @include font-size(16px);
    }
  }
}
