@use "sass:math";

@mixin create-mq($breakpoint, $min-or-max) {
  @if($breakpoint == 0) {
    @content;
  } @else {
    @media screen and (#{$min-or-max}-width: $breakpoint * 1px) {
      @content;
    }
  }
}

@mixin create-col-classes($modifier, $grid-cols, $breakpoint) {
  @include create-mq($breakpoint, 'min') {
    &-offset#{$modifier}-0 {
      margin-left: 0;
    }

    @for $i from 1 through $grid-cols {
      &-col#{$modifier}-#{$i} {
        position: relative;
        max-width: 100%;
        min-height: 1px;
        padding-right: math.div($gutter, 2) * 1px;
        padding-left: math.div($gutter, 2) * 1px;
        width: (math.div(100, math.div($grid-cols, $i))) * 1%;
        flex: 0 0 auto;
      }
      
      &-offset#{$modifier}-#{$i} {
        margin-left: (math.div(100, math.div($grid-cols, $i))) * 1%;
      }
    }
  }
}

@each $modifier , $breakpoint in $map-grid-props {
  @if($modifier == '') {
    $modifier: '-xs';
  }

  @include create-mq($breakpoint - 1, 'max') {
    .hidden#{$modifier}-down {
      display: none !important;
    }
  }

  @include create-mq($breakpoint, 'min') {
    .hidden#{$modifier}-up {
      display: none !important;
    }
  }
}

.cc-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: math.div($gutter, 2) * -1px;
  margin-left: math.div($gutter, 2) * -1px;
}

.cc {
  @each $modifier , $breakpoint in $map-grid-props {
    @include create-col-classes($modifier, $grid__cols, $breakpoint);
  }
}