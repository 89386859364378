.dropzone {
  &-wrapper {
    display: flex;
    background-color: $color-gray-100;
    border-radius: 10px;
    border: 1px dashed $color-primary;
    margin: 10px 0;
    padding: 30px;
    justify-content: center;
    align-items: center;
    color: $color-gray-300;
    cursor: pointer;

    &:hover {
      border-color: $color-primary-dark;
      color: $color-gray;
    }
  }
}

.icon {
  @include font-size(55px);
  margin-right: 16px;
  flex-shrink: 0;
}

.text {
  @include font-size(16px);
  line-height: 1.4;
}

.remove-btn {
  border: 1px $color-red solid;
  @include font-size(20px);
  border-radius: 100%;
  color: $color-red;
  background-color: transparent;
  margin-left: 10px;
  flex-shrink: 0;

  & > span {
    padding: 1px;
  }

  &:hover {
    border-color: $color-red-dark;
    color: $color-red-dark;
  }
}

.file-list {
  padding-top: 20px;
}

.error-list {
  & > span {
    @include font-size(16px);
    margin-bottom: 10px;
    display: block;
  }
}

.file-item {
  border: 1px $color-primary solid;
  padding: 12px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &__text {
    @include font-size(14px);
    color: $color-gray-400;
    flex-grow: 1;
    min-width: 0;
  }
}
