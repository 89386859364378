.info,
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.wrapper {
  height: 80%;
  width: 100%;
}

.info h1 {
  font: normal normal bold 31px/39px Orbitron;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.97px;
  color: #1e47c1;
  opacity: 1;
  margin-top: 40px;
  margin-bottom: 16px;
}

.info h2 {
  text-align: center;
  font: normal normal normal 20px/27px "Open Sans";
  font-weight: 400;
  letter-spacing: 0.63px;
  color: #1e47c1;
  opacity: 1;
  margin-top: 0;
  margin-bottom: 16px;
}

.linkButton {
  background: #fe9e4c 0% 0% no-repeat padding-box;
  padding: 10px 30px;
  border: none;
  border-radius: 8px;
  text-align: left;
  // font-family: $font-open-sans;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-top: 0;

  justify-content: center;
  align-self: center;
}

.linkButton:hover {
  cursor: pointer;
}
