.cc-form__row {
  margin-bottom: 20px;
}

.cc-form__label {
  font-weight: bold;
  @include font-size(16px);
  line-height: 1.2;
  margin-bottom: 10px;

  & > span {
    font-weight: normal;
  }
}
