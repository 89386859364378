.cc-login {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  background-color: $color-page-bg;
  transform: translate(-50%, -50%);
  padding: 30px 50px;
  box-shadow: $shadow-modal;
  border-radius: 8px;

  &__heading {
    @include font-preset("h4");
    font-family: $font-orbitron;
    font-weight: bold;
    color: $color-primary;
    margin-bottom: 20px;
  }

  &__form {
    width: 300px;
  }

  &__close {
    position: absolute;
    top: 5px;
    right: 5px;
    @include font-size(12px);
    border-radius: 50%;

    & > span {
      padding: 5px;
    }
  }

  &__text {
    padding-top: 10px;
    @include font-size(12px);
    line-height: 1.2;
    font-weight: bold;
    color: $color-gray-400;

    & > a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
