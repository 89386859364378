.field {
	&__row {
		width: 100%;
		margin-bottom: 15px;
		position: relative;
		text-align: left;

		&.mb--none {
			margin-bottom: 0;
		}
	}
}
