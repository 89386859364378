.wrapper {
  display: flex;
  min-height: 100vh;
  width: 100% !important;

  color: #333;
  font-family: "Roboto";

  .spacer {
    margin-bottom: 20px;
  }

  .main-wrapper {
    flex-grow: 1;
  }

  .product-grid {
    position: relative;
    flex-grow: 1;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;

    @media (max-width: 768px) {
      justify-content: center;
    }

    .imageWrapper {
      display: flex;
      align-items: center;
      height: 100%;
    }

    .product-card {
      width: 250px;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 15px;
      padding: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 768px) {
        width: 80%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .details {
        width: 100%;

        .name {
          font-size: 16px;
          color: #333;
          font-weight: bold;
          margin: 10px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;
        }

        .description {
          font-size: 14px;
          color: #666;
          margin-bottom: 10px;
          overflow: hidden;
          text-align: center;

          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          white-space: normal;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .wrapper {
    display: flex;
    justify-content: center;
    padding: 20px;
    flex-direction: column;
    align-items: center;

    .sidebar {
      flex-basis: auto;
      width: 100%;
    }
  }
}
