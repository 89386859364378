.noScroll {
  overflow: hidden;
}

.shop-navbar {
  display: flex;
  flex-direction: column;

  .header-wrapper {
    position: fixed;
    width: 100%;
    z-index: 999;
  }

  .header {
    display: flex;
    z-index: 100;
    justify-content: space-between;
    align-items: center;
    height: 95px;
    width: 100%;
    background-color: hsla(40, 42.86%, 94.51%, 1);
    margin-bottom: 10px;
    padding: 0 33px;
    box-shadow: 0 4px 16px 0px rgba(0, 0, 0, 0.3);

    &-buttons {
      display: flex;
      margin-left: auto;
      justify-content: flex-end;
      align-items: center;
      gap: 22px;

      svg {
        height: 32px;
        width: 32px;
      }

      a {
        margin-right: 0px !important;
      }
    }

    .image {
      align-self: center;
      z-index: 999;

      img {
        height: 40px !important;
      }
    }

    .items {
      display: flex;
      flex: 1;
      align-items: center;
      margin-left: 65px;
      font-size: 18px;
      font-weight: 100;

      a {
        margin-right: 28px;
        text-decoration: none;
      }

      .active {
        border-bottom: 1px solid #333;
      }
    }
  }

  .menuButton {
    display: none;
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 9999;
  }

  .hamburger {
    fill: none;
    stroke: #333;
    stroke-width: 6;
    transition: transform 0.3s ease;
  }

  .line {
    width: 28px;
    height: 3px;
    margin: 0 0 5px 0;
    background: black;
    transition: all 0.5s ease-out;
  }

  .isOpen .line:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .isOpen .line:nth-child(2) {
    opacity: 0;
  }

  .isOpen .line:nth-child(3) {
    transform: rotate(-45deg) translate(7px, -6px);
  }

  @media (max-width: 1300px) {
    .menuButton {
      display: block;
    }

    .items {
      position: fixed;
      top: 0;
      margin-left: 0px !important;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: white;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-left: 20px;
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;
      z-index: 10;
      text-align: left !important;

      .item {
        width: 100%;
        text-align: left;
        margin-bottom: 10px;
      }

      a {
        margin-right: 0;
        font-size: 24px;
      }

      .header-buttons {
        flex-direction: row;
        gap: 10px;
        margin-top: 20px;
        position: absolute;
        bottom: 40px;
        left: 20px;

        svg {
          height: 24px;
          width: 24px;
        }
      }
    }

    .menuOpen {
      transform: translateX(0);
    }
  }

  .row {
    margin-top: 100px;

    &--without-header {
      margin-top: 0px;
    }

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
  }

  .rightWrapper {
    display: flex;
    align-items: center;
  }

  .lang-switcher-button {
    background-color: transparent;
    border: none;
  }

  .icon-basket {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    background: transparent;

    svg {
      height: 24px;
      width: 24px;
      margin-right: 10px;
    }

    .wishlist-count {
      font-size: 1.5rem;
      color: #333;
    }
  }

  .LS {
    margin-right: 15px;
    z-index: 99;
  }
}
