//font variables
$font-open-sans: "Open Sans", sans-serif;
$font-orbitron: "Orbitron", sans-serif;
$font-roboto: "Roboto", sans-serif;
$base-font-size: 62.5%;

@font-face {
  font-family: "Orbitron";
  src: url("/public/fonts/Orbitron-Medium.ttf");
}

@font-face {
  font-family: "Roboto";
  src: url("/public/fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: "Roboto";
  src: url("/public/fonts/Roboto-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "Roboto";
  src: url("/public/fonts/Roboto-Bold.ttf");
  font-weight: 800;
}

$font-size-presets: (
  "h1": (
    "font-size": 8.8rem,
    "line-height": 1.2,
  ),
  "h2": (
    "font-size": 5.5rem,
    "line-height": 1.2,
  ),
  "h3": (
    "font-size": 4.4rem,
    "line-height": 1.2,
  ),
  "h4": (
    "font-size": 3.1rem,
    "line-height": 1.2,
  ),
  "h5": (
    "font-size": 2.2rem,
    "line-height": 1.2,
  ),
  "h6": (
    "font-size": 1.6rem,
    "line-height": 1.2,
  ),
  "body-1": (
    "font-size": 2.4rem,
    "line-height": 1.4,
  ),
  "body-2": (
    "font-size": 1.4rem,
    "line-height": 1.4,
  ),
);
