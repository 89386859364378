.cc-input {
  width: 100%;
  box-shadow: none;
  appearance: none;
  background-color: $color-gray-100;
  font-family: $font-open-sans;
  font-weight: normal;
  color: $color-primary;
  border: 1px solid $color-primary;
  transition: border-color 0.2s ease;
  border-radius: 8px;
  @include font-size(16px);
  line-height: 1;
  padding: 8px 10px;
  height: 40px;

  &:focus {
    box-shadow: none;
    outline: none;
    border-color: $color-primary-dark;
  }

  &[readonly] {
    border: 1px solid $color-blue-300;

    &:focus {
      border: 1px solid $color-blue-300;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;

    &:focus {
      border: 1px solid $color-blue-200;
    }
  }

  &.cc--is-invalid {
    border-color: $color-red;
  }

  &::placeholder {
    color: $color-primary;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: $color-primary;
  }

  &::-ms-input-placeholder {
    color: $color-primary;
  }
}

.cc-input-container {
  width: 100%;
}
