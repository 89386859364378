.cc--text-ellipsis {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cc--center {
  text-align: center;
}

.cc--mt {
  margin-top: 1rem;
}

.cc--uppercase {
  text-transform: uppercase;
}

.cc--mb {
  margin-bottom: 1rem;
  &-xs {
    margin-bottom: 16px;
  }
}

.cc--text {
  &-bold {
    font-weight: bold;
  }

  &-color {
    &-primary {
      color: $color-primary;
    }

    &-white {
      color: #fff;
    }

    &-dark {
      color: $color-black;
    }
  }

  &-underlined {
    text-decoration: underline;
  }
}

.cc-img--circle {
  border-radius: 50%;
}

.cc--flex {
  display: flex;
}

.cc-modal--on {
  overflow: hidden;
}

.cc-custom-list {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.cc-main-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  background: rgba(#fff, 0.6);
}
