.cc-checkbox {
  display: block;

  & > label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 36px;
    font-weight: normal;
    font-family: $font-open-sans;
    font-size: 1.6rem;
    line-height: 20px;
    color: $color-black;
    min-height: 16px;

    &:before {
      content: "";
      position: absolute;
      top: 2px;
      left: 0;
      display: block;
      margin-right: 8px;
      width: 24px;
      height: 24px;
      border: 1px solid $color-gray-400;
      transition: border-color 0.2s ease, background-color 0.2s ease;
      border-radius: 5px;
    }

    &:after {
      position: absolute;
      content: "";
      pointer-events: none;
      display: block;
      width: 7px;
      height: 14px;
      border: solid $color-gray-400;
      border-width: 0 2px 2px 0;
      top: 5px;
      left: 9px;
      transform: rotate(45deg);
      opacity: 0;
      transition: opacity 0.2s ease;
    }
  }

  input {
    display: none;
  }

  input:not(:disabled):not(:checked) ~ label {
    &:hover {
      &:before {
        background-color: $color-gray-100;
      }
    }
  }

  input:checked ~ label {
    &:before {
      background-color: $color-gray-100;
    }

    &:after {
      opacity: 1;
    }
  }

  input:disabled ~ label {
    user-select: none;
    cursor: not-allowed;
    opacity: 0.8;
  }

  &.cc--is-invalid {
    label {
      color: $color-red;

      &:before {
        border-color: $color-red;
      }
    }
  }
}
