.action {
  &-group {
    display: flex;
    margin-left: -8px;
    margin-right: -8px;
    margin-bottom: 10px;
  }

  &-item {
    width: 50%;
    padding-left: 8px;
    padding-right: 8px;
  }

  &-btn {
    border-radius: 10px;
    width: 100%;
    @include font-size(18px);

    & > span {
      padding: 11px 14px;
    }
  }
}

.google-icon {
  margin-right: 10px;
  width: 30px;
  height: 30px;
}

.google-button {
  background-color: $color-gray-100;
  border-color: $color-gray-400;

  &:hover {
    background-color: $color-gray-200;
  }
}

.fb-icon {
  margin-right: 10px;
  width: 30px;
  height: 30px;
}

.fb-button {
  background-color: #1977F3;
  border-color: #1977F3;

  &:hover {
    background-color: $color-primary;
    border-color: $color-primary;
  }
}
