.cc--of {
    display: block;
    width: 100%;
    height: 100%;
    @include object-fit(cover);
    
    &-bg {
        pointer-events: none;
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    &-left-top {
        @include object-fit(contain, 0 0);
    }
}
