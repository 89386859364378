.cc-select {
  .cc-select {
    @include font-size(14px);
    font-family: $font-open-sans;

    &__control {
      line-height: 1.2;
      font-weight: normal;
      color: $color-black;
      border: 1px solid $color-primary;
      border-radius: 10px;
      background-color: $color-gray-100;
      box-shadow: none;
      transition: border-color 0.2s ease;
      min-height: 54px;

      &:hover {
        border-color: $color-primary-dark;
      }

      &--is-focused {
        border-color: $color-primary-dark;
      }

      &--menu-is-open {
        .cc-select__dropdown-indicator {
          transform: rotate(180deg);
        }
      }

      &--is-disabled {
       pointer-events: none;
      }
    }

    &__multi-value {
      border: 1px $color-primary solid;
      background-color: #fff;
      border-radius: 5px;
      
      &__label {
        color: $color-primary;
        font-family: $font-open-sans;
        padding-left: 10px;
        @include font-size(16px);
      }

      &__remove {
        cursor: pointer;
        border-radius: 5px;
      }
    }

    &__value {
      &-container {
        @include font-size(16px);
        padding: 10px 22px;
      }
    }

    &__placeholder {
      color: $color-primary;
      font-family: $font-open-sans;
      font-weight: normal;
      margin: 0;
      pointer-events: none;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      left: 0;

      & ~ div {
        margin: 0!important;
        padding: 0!important;
        color: $color-black;
      }
    }

    &__single-value {
      color: $color-primary;
      
      & ~ div {
        margin: 0!important;
        padding: 0!important;
        color: $color-primary;
      }
    }

    &__input {}

    &__indicators {
      @include font-size(14px);
      padding: 5px 22px;
      flex-shrink: 0;
    }

    &__loading-indicator {
      padding: 0;
      margin-right: 10px;
    }

    &__dropdown-indicator {
      padding: 0;
      color: $color-primary;
      @include font-size(14px);
      transition: transform 0.2s ease;
    }

    &__clear-indicator {
      color: $color-black;;
      @include font-size(14px);
      cursor: pointer;
      transition: color 0.2s ease;
      margin-right: 5px;
      padding: 5px;

      &:hover {
        color: $color-gray;
      }
    }

    &__menu {
      z-index: 10;
      box-shadow: 0 0 12px rgba($color-black, 0.2);
      border-radius: 10px;
      margin-top: 10px;
      background-color: #fff;

      &-list {
        margin-top: 8px;
        margin-bottom: 8px;
        overflow-x: hidden;
      }
    }

    &__option {
      color: $color-primary;
      @include font-size(14px);
      background-color: transparent;
      transition: background-color 0.2s ease;
      padding: 7px 24px;
      font-weight: bold;
      cursor: pointer;

      &:active {
        background-color: $color-gray-200;
      }

      &:focus {
        outline: none;
      }

      &--is-focused {
        background-color: $color-gray-200;
      }

      &--is-selected {
        background-color: $color-gray-100;
        color: $color-primary;
        pointer-events: none;

        &:active {
          background-color: $color-gray-200;
        }
      }
    }
  }

  &.cc-select--is-disabled {
    &:not(.cc--is-read-only) {
      pointer-events: auto;
      cursor: not-allowed;
      opacity: 0.6;
    }

    &.cc-select--is-read-only {
      pointer-events: auto;
      opacity: 0.8;
    }
  }

  &.cc--is-invalid {
    .cc-select__control {
      border: 1px solid $color-red;
    }
  }
}