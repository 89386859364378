.imageWrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.comingSoonBanner {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  font-size: 0.9em;
  font-weight: bold;
  z-index: 1;
}

.wrapper {
  display: flex;
  color: #333;
  font-family: "Roboto";
  width: 100%;

  @media (max-width: 768px) {
    padding: 20px;
  }

  .test {
    text-wrap: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;
    max-width: 50%;
    background-color: red;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Roboto";
    font-weight: normal;
  }

  .no-products {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    padding: 20px;
  }

  .main-wrapper {
    flex-grow: 1;
  }

  .product-grid {
    position: relative;
    flex-grow: 1;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;

    @media (max-width: 768px) {
      justify-content: center;
    }

    .loader {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.4);
      height: 100%;
      width: 100%;
      z-index: 9999;
    }

    .loader-custom {
      color: white;
    }

    .product-card {
      width: 250px;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 15px;
      padding: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .details {
        width: 100%;

        min-height: 20%;

        .name {
          font-size: 16px;
          color: #333;
          font-weight: bold;
          margin: 10px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;
        }

        .description {
          font-size: 14px;
          font-weight: 300;
          color: #666;
          margin-bottom: 10px;
          overflow: hidden;
          text-align: center;

          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          white-space: normal;
        }
      }

      .price {
        font-size: 14px;
        font-weight: 800;
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: 768px) {
  .wrapper {
    flex-direction: column;
    align-items: center;
  }
}
