.cc-checkout-header {
  max-width: 1080px;
  margin: 0 auto;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
  padding-top: 55px;
  position: relative;
  z-index: 10;

  &__logo {
    width: 165px;
    height: auto;

    img {
      vertical-align: middle;
    }
  }

  &__back {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-right: 10px;

    &:hover {
      & > span {
        text-decoration: underline;
      }
    }

    &-btn {
      @include font-size(20px);
      border-radius: 50%;
      margin-right: 10px;

      & > span {
        padding: 2px;
      }
    }
  }
}

.cc-lang-switcher__btn {
  @include font-size(18px);
  font-weight: bold;
  line-height: 1.2;
  border-radius: 5px;

  & > span {
    padding: 2px 5px;
    text-transform: uppercase;
  }

  & .cc-icon {
    transform: rotate(-90deg);
  }
}
