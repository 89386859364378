.cc-chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &-settings {
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    z-index: 999;
  }

  &-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &-messages {
    width: 100%;
    padding: 0 20px;
    flex-grow: 1;
    overflow-y: auto;
  }

  &-textfield-form {
    background-color: $color-gray-200;
    padding: 12px;
    width: 100%;
  }

  &-textfield {
    width: 100%;
    background-color: $color-white;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }

  &-textarea {
    flex-grow: 1;
    padding: 1rem;
    width: 100%;
    border: 0px;
    resize: none;
    min-height: 60px;
    max-height: 60px;
    border-radius: 10px;
  }

  &-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    margin: 0 10px;
    background-color: transparent;
    color: $color-primary;
    border: 0;
    cursor: pointer;
  }

  &-login-form {
    margin: 2rem;
    padding: 2rem 2rem 0 2rem;
    background-color: $color-white;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .header {
      @include font-size(20px);
      color: $color-gray-300;
      margin-bottom: 1rem;
      font-weight: 600;
    }

    .input {
      width: 100%;
      margin: 1rem 0;
    }

    .button {
      width: 100%;
      margin: 0.75rem 0;

      &-skip {
        @extend .button;
        border: 0;
        background-color: transparent;
        color: $color-gray-300;
      }
    }
  }
}

.a-enter-vr-button {
  left: 30px !important;
  bottom: 60px;
  right: auto;
}

.a-enter-vr.a-hidden {
  display: unset !important;
}
