button {
  display: block;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  color: #333;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #e9ecef;
    color: #212529;
  }
}
