.scrollable {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 250px;
  overflow-y: auto;

  @media (max-width: 768px) {
    max-height: 150px;
    gap: 2px;
  }
}

.categoryWrapper {
  display: flex;
  flex-direction: column;
}

.categoryRow {
  display: flex;
  align-items: center;
  gap: 10px;

  .toggleButton {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    line-height: 1;
    padding: 0 5px;
  }
}

.box {
  .scrollable {
    margin-top: 15px;
  }

  @media (max-width: 768px) {
    button {
      font-size: 10px;
      padding: 5px;
    }
  }
}

.subcategories {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 5px;
  padding-left: 20px;
}

.sidebar {
  min-width: 300px !important;
  max-width: 300px !important;
  padding: 20px;
  font-family: "Roboto" !important;

  span {
    font-size: 18px;
  }

  .active {
    background-color: #007bff;

    &:hover {
      background-color: #0056b3;
    }
  }

  input {
    width: 100%;
    border-radius: 10px;
    padding: 5px;
  }

  h3 {
    color: #333;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eaeaea;
  }

  section {
    margin-bottom: 20px;
  }

  .toggleSection {
    font-weight: bold;
    font-size: 18px;
    color: #007bff;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
}

.spacer {
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
}

.toggler {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  cursor: pointer;

  span {
    &:hover {
      color: #0056b3;
    }
    margin-right: 5px;
  }
}

@media (max-width: 768px) {
  .sidebar {
    min-width: 100% !important;
    padding: 10px;
  }
}
