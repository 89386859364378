.cc-button {
  display: inline-block;
  position: relative;
  outline: none;
  text-align: center;
  text-decoration: none;
  user-select: none;
  line-height: inherit;
  cursor: pointer;
  padding: 0;
  border-width: 1px;
  border-style: solid;
  font-family: $font-open-sans;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }

  &::-moz-focus-inner {
    padding: 0;
  }

  &.cc--disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  & > span {
    height: 100%;
    position: relative;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.cc--primary {
    color: $color-primary;
  }

  &.cc--orange {
    color: $color-orange;
  }

  &.cc--gray {
    color: $color-gray;
  }

  &.cc--white {
    color: #fff;
  }

  &.cc--red {
    color: $color-red;
  }

  &.cc--bg-white {
    border-color: #fff;
  }

  &.cc--outlined {
    background-color: transparent;
    transition: background-color 0.2s ease;

    &.cc--bg-primary {
      border-color: $color-primary;
      background-color: #fff;

      &:hover {
        background-color: $color-blue-200;
      }

      &:active {
        border-color: $color-primary-dark;
        color: $color-primary-dark;
      }
    }

    &.cc--bg-orange {
      border-color: $color-orange;
      background-color: #fff;

      &:hover {
        background-color: $color-orange-100;
      }

      &:active {
        border-color: $color-orange-300;
        color: $color-orange-300;
      }
    }

    &.cc--bg-gray {
      border-color: $color-gray;
      background-color: #fff;

      &:hover {
        border-color: $color-black;
        color: $color-black;
      }

      &:active {
        background-color: $color-gray-400;
        border-color: $color-black;
        color: $color-black;
      }
    }

    &.cc--bg-red {
      border-color: $color-red;
      background-color: #fff;

      &:hover {
        border-color: $color-red-dark;
        color: $color-red-dark;
      }

      &:active {
        background-color: $color-gray-200;
        border-color: $color-red-dark;
        color: $color-red-dark;
      }
    }
  }

  &.cc--filled {
    transition: background-color 0.2s ease, border-color 0.2s ease;

    &.cc--bg-primary {
      background-color: $color-primary;
      border-color: $color-primary;

      &:hover {
        background-color: $color-primary-dark;
        border-color: $color-primary-dark;
        color: #fff;
      }

      &:active {
        color: $color-gray-200;
      }
    }

    &.cc--bg-orange {
      background-color: $color-orange;
      border-color: $color-orange;

      &:hover {
        background-color: $color-orange-300;
        border-color: $color-orange-300;
        color: #fff;
      }

      &:active {
        background-color: $color-orange-400;
        border-color: $color-orange-400;
        color: $color-gray-200;
      }
    }

    &.cc--bg-white {
      background-color: #fff;

      &:hover {
        background-color: #fff;
        border-color: #fff;
        color: $color-black;
      }

      &:active {
        background-color: $color-gray-200;
        border-color: $color-gray-200;
        color: $color-black;
      }
    }

    &.cc--bg-dark-gray {
      background-color: $color-gray-400;
      border-color: $color-gray-400;

      &:hover {
        background-color: $color-black;
        border-color: $color-black;
        color: $color-gray-100;
      }

      &:active {
        background-color: $color-black;
        border-color: $color-black;
        color: $color-gray-100;
      }
    }

    &.cc--bg-gray {
      background-color: $color-gray-300;
      border-color: $color-gray-300;

      &:hover {
        background-color: $color-gray-400;
        border-color: $color-gray-400;
        color: $color-gray-100;
      }

      &:active {
        background-color: $color-gray;
        border-color: $color-gray;
        color: $color-gray-100;
      }
    }
  }

  &.cc--is-loading {
    pointer-events: none;
    overflow: hidden;
  }
}

.cc-button {
  &.cc--weight-normal {
    font-weight: normal;
  }

  &.cc--weight-bold {
    font-weight: bold;
  }
}

.cc-button {
  &.cc--normal {
    border-radius: 10px;
    @include font-size(15px);
    line-height: 1.4;

    & > span {
      padding: 11px 32px;
    }
  }

  &.cc--large {
    border-radius: 14px;
    @include font-size(20px);
    line-height: 1.4;

    & > span {
      padding: 12px 32px;
    }
  }

  &.cc--small {
    border-radius: 10px;
    @include font-size(14px);
    line-height: 1.4;

    & > span {
      padding: 8px 10px 10px;
    }
  }

  &.cc--full-width {
    width: 100%;
  }
}

.cc-button-loader {
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color-gray-300, 0.9);
  color: #fff;
  pointer-events: none;
  border-radius: 0;
  @include font-size(12px);
}
