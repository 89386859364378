@use "sass:math";
/*----------------------
Rem font unit with px fallback and optional line height
-----------------------*/
@mixin font-size($font-size) {
    font-size: (parseVal(math.div($font-size, 10))) * 1rem;
}

@mixin line-height($font-size, $line-height) {
    line-height: math.div($line-height, $font-size);
}

@mixin font-preset($preset) {
    $preset-values: map_get($font-size-presets, $preset);

    @if ($preset-values) {
        font-size: map_get($preset-values, 'font-size');
        line-height: map_get($preset-values, 'line-height');
        letter-spacing: map_get($preset-values, 'letter-spacing');
    }
}
