.cc-quantity-container {
  display: flex;
}

.cc-quantity-action__button {
  @include font-size(14px);
  border: 1px $color-gray-400 solid;
  color: $color-gray-400;
  border-radius: 50%;
  background-color: #fff;

  & > span {
    padding: 7px;
  }

  &:hover {
    background-color: $color-gray-200;
  }

  &:active {
    border-color: $color-gray-400;
    background-color: $color-gray-400;
    color: #fff;
  }
}

.cc-quantity-input {
  width: 100%;
  box-shadow: none;
  appearance: none;
  background-color: #fff;
  font-family: $font-open-sans;
  font-weight: bold;
  color: $color-gray-400;
  border-radius: 10px;
  border: 1px solid $color-gray-400;
  transition: border-color 0.2s ease;
  @include font-size(16px);
  line-height: 1;
  padding: 3px 14px;
  height: 30px;
  text-align: center;

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &__wrapper {
    width: 84px;
    margin-left: 5px;
    margin-right: 5px;
  }
}
