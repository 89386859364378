.cc-project-bar {
  position: fixed;
  z-index: 3;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  border: 1px #fff solid;
  padding: 17px 12px;
  border-radius: 25px 25px 0 0;
  background-color: rgba($color-blue-100, 0.3);
  backdrop-filter: blur(37px);

  &__action-btn {
    border-radius: 10px;
    @include font-size(18px);
    min-width: 42px;
    margin-bottom: 2px;

    & > span {
      padding: 4px;
    }
  }

  &__item {
    padding-left: 8px;
    padding-right: 8px;

    & > span {
      @include font-size(12px);
      line-height: 1.2;
      display: block;
      color: $color-gray-400;
    }
  }
}
