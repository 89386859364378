$color-black: #000;
$color-white: #ffffff;
$color-page-bg: #f4f8ff;
$color-red: #d90f36;
$color-red-dark: #c72443;
$color-green: #2da510;

$color-regionalis: hsla(40, 42.86%, 94.51%, 1);

$color-primary: #1e47c1;
$color-primary-dark: #122f64;

$color-blue-100: #f0f2f8;
$color-blue-200: #d4e4fe;
$color-blue-300: #2a76fa;
$color-blue-400: #5591fb;

$color-gray: #1c1c1c;
$color-gray-100: #f7f7f7;
$color-gray-200: #dbdbdb;
$color-gray-300: #a8a8a8;
$color-gray-400: #363636;

$color-orange: #fe9e4c;
$color-orange-100: #ffecdb;
$color-orange-300: #cb7e3d;
$color-orange-400: #98602e;
