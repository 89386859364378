.cc-checkout-item {
  padding: 8px;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba($color-black, 0.1);
  border-radius: 10px;
  border: 1px $color-gray-300 solid;

  &__wrapper {
    display: flex;
    margin-bottom: 20px;
  }

  &__image {
    width: 115px;
    height: 136px;
    margin-right: 15px;
    flex-shrink: 0;

    img {
      border-radius: 5px;
    }
  }

  &__name {
    color: $color-gray-400;
    @include font-size(16px);
    line-height: 1.3;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  &__text {
    @include font-size(14px);
    line-height: 1.7;
    color: #020202;
  }

  &__info {
    flex-grow: 1;
    padding-top: 10px;
    min-width: 0;
  }

  &__action {
    display: flex;
    justify-content: space-between;
  }

  &__remove {
    border-radius: 50%;
    @include font-size(14px);
    border-color: rgba($color-gray-400, 0.5);
    color: rgba($color-gray-400, 0.5);

    &:hover {
      border-color: $color-gray-400;
      color: $color-gray-400;
    }

    & > span {
      padding: 7px;
    }
  }
}

.cc-checkout-list {
  &.cc--vertical {
    .cc-checkout-list__item {
      margin-bottom: 8px;
    }
  }

  &.cc--horizontal {
    margin-left: -22px;
    margin-right: -22px;
    display: flex;
    flex-wrap: wrap;

    .cc-checkout-list__item {
      padding-left: 22px;
      padding-right: 22px;
      width: 33.33%;
      margin-bottom: 10px;
    }
  }
}
