.footer {
  position: relative;

  background-color: #f7f2eb;
  text-align: center;
  min-height: 554px;
  width: 100%;

  display: flex;
  flex-direction: column;
}

.container {
  @media (max-width: 1300px) {
    flex-direction: column;
    justify-content: center;
    min-width: 100%;
    padding: 0 20px;
  }

  .bold {
    font-weight: bold;
  }

  span {
    color: rgb(132, 189, 219);
  }

  width: 100%;
  padding: 0 100px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding-bottom: 40px !important;

  .left {
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: left;
    min-width: 600px;

    @media (max-width: 1300px) {
      min-width: 0;
      width: 100%;
      text-align: center;

      input {
        max-width: 500px;
      }

      .newsletter {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }

    span {
      margin-top: 60px;
      font-size: 21px;
    }

    .logo {
      margin-top: 15px;
      img {
        height: 68px;
      }
    }

    .newsletter {
      font-family: "Open Sans";
      font-weight: unset !important;
      margin-top: 69px;

      span {
        color: black;
      }

      span:nth-child(2) {
        margin-left: 8px;
        font-size: 12px;
        color: gray;
      }

      input {
        margin-top: 5px;
        width: 100%;
        padding: 12px;
        font-size: 20px;
        border: 0;
      }

      button {
        margin-top: 16px;
        background-color: hsla(192.63, 42.22%, 64.71%, 1);
        color: white;
        border-radius: 5%;
        font-size: 10px;
        padding: 20px 38px;
      }

      &-checkbox {
        display: inline;

        input {
          width: auto;
        }
      }
    }
  }

  .middle {
    display: flex;
    flex-direction: row;
    gap: 160px;
    height: 100%;
    padding-top: 110px;
    min-width: 480px;

    @media (max-width: 1300px) {
      display: flex;
      min-width: 100%;
      gap: 0px;
      justify-content: space-around;
      padding-top: 20px;
      text-align: center;
    }

    text-align: left;

    &-title {
      padding-bottom: 10px;
      font-weight: bold;
    }

    .middle-left,
    .middle-right {
      span {
        font-size: 12px;
      }

      .links {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .links a {
        padding-top: 22px;
      }

      .links a:first-child {
        padding-bottom: 12px;
        padding-top: 10px;
      }

      a {
        color: black;
        font-size: 12px;
      }
    }
  }

  .right {
    display: flex;
    gap: 10px;

    min-width: 125px;
    padding-top: 115px;
    height: 100%;

    @media (max-width: 1300px) {
      min-width: 0;

      padding-top: 20px;
      padding-bottom: 20px;
      justify-content: center;
    }

    svg {
      height: 32px;
      width: 32px;
    }

    a {
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20%;
      background-color: hsla(192.63, 42.22%, 64.71%, 1);
    }
  }
}

.social a {
  margin: 0 10px;
  color: #3f84ae;
}

.social i {
  font-size: 20px;
}

.copyright {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 25px;
  font-weight: bold;
  font-size: 12px;
}
