.wrapper {
  display: flex;
  flex-direction: column;
}

.disabled {
  cursor: not-allowed;
  background-color: #f9f9f9;
  border-color: #e0e0e0;
  color: #ccc;
}

.comingSoonBanner {
  position: relative;
  padding: 10px;
  background-color: #007bff;
  color: white;
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
}

.variants {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  max-height: 120px;
  overflow-y: scroll;

  .variantOption {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #9a9797;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s, transform 0.3s ease,
      box-shadow 0.3s ease;

    &:hover {
      background-color: #f9f9f9;
    }

    &:active {
      transform: scale(0.97);
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    }

    &.disabled {
      cursor: not-allowed;
      background-color: #f9f9f9;
      border-color: #e0e0e0;
      color: #ccc;
    }

    .outOfStock {
      color: #ff0000;
      font-weight: bold;
    }

    &.selected {
      background-color: #495057;
      color: #fff;

      .variantPrice {
        color: #fff;
      }
    }

    .variantTitle {
      align-self: flex-start;
      font-weight: bold;
    }

    .stock {
      align-self: center;
      display: flex;
      justify-content: space-between;
    }

    .variantPrice {
      align-self: flex-end;
      margin-left: 10px;
      font-size: 16px;
      color: #555;
    }
  }
}

.productDetail {
  display: flex;
  justify-content: center;
  padding: 20px;
  align-items: flex-start;
  width: 100%;

  @media (max-width: 768px) {
    align-items: center;
    flex-direction: column;
  }
}

.productImage {
  flex: 2;
  display: flex;
  position: relative;
}

.mainProductImg {
  width: 100%;
  object-fit: contain;
  height: auto;
  max-height: 400px;
}

.thumbnailContainer {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  align-items: center;
}

.thumbnailImg {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-bottom: 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}

.productContent {
  flex: 2;
  margin-left: 100px;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 20px;
  }

  display: flex;
  flex-direction: column;
  font-family: "Roboto";

  .title {
    font-size: 28px;
    margin-top: 0;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eaeaea;
  }

  .description {
    font-size: 16px;
    font-weight: 100;
    margin-top: 0;
    margin-bottom: 10px;
  }

  .price {
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 10px;
  }
}

.buttons {
  margin-top: 15px;
  font-size: 16px;

  button {
    width: 100%;
    font-weight: 800;
    transition: background-color 0.2s, box-shadow 0.2s, transform 0.2s;

    &:hover {
      opacity: 0.9;
    }

    &:active {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
      transform: scale(0.98);
    }

    color: white;
  }

  .addToCartButton {
    background-color: #007bff;

    &:hover {
      background-color: #0056b3;
    }

    &:active {
      background-color: #004085;
    }
  }

  .updateQuantityButton {
    background-color: #28a745;

    &:hover {
      background-color: #218838;
    }

    &:active {
      background-color: #1e7e34;
    }
  }

  .removeFromCartButton {
    background-color: #dc3545;

    &:hover {
      background-color: #c82333;
    }

    &:active {
      background-color: #bd2130;
    }
  }
}

.hr {
  border: 0;
  height: 1px;
  background-color: #ccc;
  width: 100%;
}

.quantity {
  margin-top: 1rem;

  > div {
    margin-top: 1rem;
  }
}
