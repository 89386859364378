.cc-loader:before,
.cc-loader:after,
.cc-loader {
  border-radius: 50%;
  animation-fill-mode: both;
  animation: loader 1.8s infinite ease-in-out;
}

.cc-center-in-screen {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;

  transform: translate(-50%, -50%) !important;
}

.cc-loader {
  color: currentcolor;
  position: relative;
  transform: translateZ(0);
  animation-delay: -0.16s;
  top: -1em;
  display: inline-block;
  width: 1em;
  height: 1em;

  &:before {
    right: 100%;
    animation-delay: -0.3s;
  }

  &:after {
    left: 100%;
  }

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: inherit;
    height: inherit;
  }
}

@keyframes loader {
  0%,
  80%,
  100% {
    box-shadow: 0 1em 0 -1em;
  }
  40% {
    box-shadow: 0 1em 0 -0.2em;
  }
}
