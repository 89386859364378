.basket-page {
  display: flex;
  flex-direction: column;
  // height: 100%;
  width: 100%;

  .error {
    color: red;
    font-size: 1.2em;
    text-align: center;
    margin-bottom: 20px;
  }

  .basket-shops-container {
    font-family: "Roboto";

    margin-bottom: 16px;

    .instructionText {
      margin-bottom: 12px;
      font-size: 14px;
      color: #333;
    }

    .basketShops {
      display: flex;
      overflow-x: auto;
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 8px;
      background-color: #f9f9f9;
      scrollbar-width: thin;
      scrollbar-color: #ccc #f9f9f9;
    }

    .basketShops::-webkit-scrollbar {
      height: 8px;
    }

    .basketShops::-webkit-scrollbar-thumb {
      background-color: #ccc;
      border-radius: 4px;
    }

    .basketShops::-webkit-scrollbar-track {
      background-color: #f9f9f9;
    }

    .basketShop {
      padding: 10px 14px;
      margin-right: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
      background-color: #fff;
      cursor: pointer;
      transition: background-color 0.4s ease, box-shadow 0.4s ease,
        transform 0.2s ease;
      white-space: nowrap;
    }

    .basketShop:hover {
      transform: scale(1.05);
    }

    .basketShopSelected {
      background-color: #007bff;
      color: #fff;
      box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
    }

    .basketShopSelected h4 {
      color: #fff;
    }

    h4 {
      margin: 0;
      font-size: 14px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Roboto";
    font-weight: normal;
  }

  .variants {
    display: flex;
    flex-direction: row;
    gap: 50px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .button-and-options {
    display: flex;
    align-items: center;
    flex-direction: row;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }

    .payment-methods {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;

      margin-right: 12px;

      svg {
        height: 48px;
        width: 48px;
        transition: transform 0.3s ease-in-out;

        &:hover {
          transform: scale(1.1);
        }

        &:active {
          transform: scale(0.9);
        }
      }
    }
  }

  .coupon {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    label {
      font-size: 1rem;
      color: #333;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
      text-align: center;

      &:nth-child(3) {
        margin-top: 1.5rem;
      }
    }

    input {
      padding: 0.5rem;
      font-size: 1rem;
      color: #333;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: #fff;
      transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
        transform 0.2s ease;

      &:focus {
        border-color: #007bff;
        outline: none;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
      }

      &:hover {
        border-color: #0056b3;
      }
    }

    .btn {
      background-color: #007bff;
      color: white;
      border: none;
      padding: 5px 10px;
      cursor: pointer;
      transition: background-color 0.3s, transform 0.2s;

      &:hover {
        background-color: #0056b3;
        transform: scale(1.05);
      }

      &:active {
        transform: scale(0.95);
      }
    }
  }

  .country-select {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    label {
      font-size: 1rem;
      color: #333;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
      text-align: center;

      &:nth-child(3) {
        margin-top: 1.5rem;
      }
    }

    select {
      padding: 0.5rem;
      font-size: 1rem;
      color: #333;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: #fff;
      transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
        transform 0.2s ease;

      &:focus {
        border-color: #007bff;
        outline: none;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
      }

      &:hover {
        border-color: #0056b3;
      }
    }

    option {
      font-size: 1rem;
      color: #333;
    }
  }

  .empty-basket {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    font-size: 1.5em;
  }

  .basket-container {
    flex: 1;
    padding: 20px;
    overflow-y: auto;

    .purchase-section {
      margin-bottom: 20px;
      padding: 10px;
      transition: border-color 0.2s;

      .purchase-section-products {
        max-height: 420px;
        overflow-y: auto;
      }

      .product {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        @media (max-width: 768px) {
          flex-direction: column;
          text-align: center;
        }

        img {
          width: 120px;
          height: 100%;
          object-fit: cover;
          margin-right: 15px;
          transition: transform 0.3s ease;

          &:hover {
            transform: scale(1.05);
          }

          &:active {
            transform: scale(0.95);
          }
        }

        .product-details {
          flex-grow: 1;

          @media (max-width: 768px) {
            h3 {
              font-size: 3rem;
            }
          }

          .alert {
            background-color: #f8d7da;
            color: #721c24;
            border: 1px solid #f5c6cb;
            padding: 5px 10px;
            border-radius: 4px;
            margin-top: 10px;
            transition: opacity 0.3s ease-in-out;

            &:hover {
              opacity: 0.8;
            }

            &:active {
              opacity: 0.6;
            }
          }

          .btn-disabled {
            background-color: #ccc;
            color: white;
            border: none;
            padding: 5px 10px;
            cursor: not-allowed;
          }

          .status {
            margin-bottom: 10px;

            span {
              display: inline-block;
              padding: 5px 10px;
              border-radius: 4px;
              font-size: 0.9rem;
              font-weight: bold;
              color: #fff;
              transition: background-color 0.3s ease-in-out, transform 0.2s ease;

              &.status-active {
                background-color: #28a745;

                &:hover {
                  transform: scale(1.05);
                }

                &:active {
                  transform: scale(0.95);
                }
              }

              &.status-coming-soon {
                background-color: #ffc107;
                color: #333;

                &:hover {
                  background-color: #e0a800;
                  transform: scale(1.05);
                }

                &:active {
                  transform: scale(0.95);
                }
              }

              &.status-in-preparation {
                background-color: #17a2b8;

                &:hover {
                  background-color: #138496;
                  transform: scale(1.05);
                }

                &:active {
                  transform: scale(0.95);
                }
              }
            }
          }

          h3 {
            margin: 0;
          }

          .variant {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            align-items: flex-start;

            @media (max-width: 768px) {
              align-items: center;
            }

            h6 {
              margin: 5px 0;
            }

            .quantity-control {
              display: flex;
              align-items: center;

              button {
                padding: 0 10px;
                cursor: pointer;
                transition: background-color 0.2s, transform 0.2s;

                &:hover {
                  background-color: #ccc;
                  transform: scale(1.05);
                }

                &:active {
                  transform: scale(0.95);
                }
              }

              span {
                margin: 0 10px;
              }
            }

            .remove-btn {
              background-color: #ff4d4f;
              color: white;
              border: none;
              padding: 5px 10px;
              cursor: pointer;
              margin-top: 10px;
              transition: background-color 0.3s, transform 0.2s;

              &:hover {
                background-color: #e03e2f;
                transform: scale(1.05);
              }

              &:active {
                transform: scale(0.95);
              }
            }
          }
        }
      }

      .total-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px;
        border: 1px solid #ddd;
        border-radius: 10px;
        background-color: #f9f9f9;
        @media (max-width: 768px) {
          align-items: center;
        }
      }

      .total-title {
        font-size: 1.2em;
        margin-bottom: 10px;
      }

      .details-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
      }

      .input-group {
        display: flex;
        flex-direction: column;
        min-width: 300px;
        max-width: 300px; /* Limit the width of input fields */
        margin-bottom: 8px;
      }

      label {
        margin-bottom: 5px;
        font-size: 1em; /* Increased font size */
      }

      .required {
        color: red;
        margin-left: 5px;
        font-size: 1.2em; /* Increased size of the required asterisk */
      }

      input[type="text"],
      input[type="email"],
      select {
        padding: 6px;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-top: 5px;
        transition: border-color 0.3s;
        width: 100%; /* Ensure the input fields don't exceed their container's width */
      }

      input[type="text"]:focus,
      input[type="email"]:focus,
      select:focus {
        border-color: #4caf50;
      }

      .button-and-options {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        @media (max-width: 768px) {
          width: 100%;
        }
      }

      .payment-methods {
        display: flex;
        gap: 10px;
        justify-content: center;
        flex-wrap: wrap;
      }

      .checkout-btn {
        background-color: #4caf50;
        color: white;
        border: none;
        padding: 10px 20px;
        cursor: pointer;
        transition: background-color 0.3s, transform 0.2s;
        &:hover {
          background-color: #3e8e41;
          transform: scale(1.05);
        }
        &:active {
          transform: scale(0.95);
        }
        &.btn-disabled {
          background-color: #ddd;
          cursor: not-allowed;
        }
      }
    }

    .overall-total {
      font-size: 1.2em;
      font-weight: bold;
      text-align: right;
      padding-right: 20px;
    }
  }

  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .popup-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 450px;
    width: 90%;
  }

  .popup-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
  }

  .popup-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;

    background-color: #28a745;
    color: #fff;
  }
}
