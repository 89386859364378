.cc--clamped {
    display: block;
    display: -webkit-box;
    white-space: normal;
    -webkit-box-orient: vertical;

    text-overflow: ellipsis;
    overflow: hidden;

    @for $i from 1 through 10 {
        &.cc--lines-#{$i} {
            -webkit-line-clamp: $i;
        }
    }
}
