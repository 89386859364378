.cc-checkout-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  padding-bottom: 30px;

  &__text {
    @include font-size(18px);
    line-height: 1.3;
    padding-top: 10px;
    padding-bottom: 5px;
  }

  &__divider {
    position: relative;
    width: 100%;
    height: 1px;
    background-color: $color-gray-300;

    &:before,
    &:after {
      content: "";
      width: 10px;
      height: 1px;
      background-color: $color-gray-300;
      position: absolute;
      right: 0;
    }

    &:before {
      transform: rotate(45deg) translateY(-4px);
    }

    &:after {
      transform: rotate(-45deg) translateY(4px);
    }
  }

  &__container {
    max-width: 1080px;
    margin: 0 auto;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  &__row {
    display: flex;
    align-items: flex-end;
  }

  &__info {
    flex-grow: 1;
  }

  &__chat-btn {
    @include font-size(30px);
    border-radius: 50%;
    box-shadow: 0 3px 15px rgba($color-black, 0.25);
    margin-left: 16px;
    flex-shrink: 0;

    & > span {
      padding: 20px;
    }
  }
}
