.cc-avatar {
  border-radius: 100%;

  img {
    border-radius: 100%;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  &-b-primary {
    @extend .cc-avatar;
    border: 1px solid;
    border-color: $color-primary;
  }
  &-b-white {
    @extend .cc-avatar;
    border: 1px solid;
    border-color: $color-page-bg;
  }
  &-b-none {
    @extend .cc-avatar;
    border: 0;
  }

  &-sm {
    @extend .cc-avatar;
    width: 30px;
    height: 30px;
  }
  &-md {
    @extend .cc-avatar;
    width: 40px;
    height: 40px;
  }
  &-lg {
    @extend .cc-avatar;
    width: 50px;
    height: 50px;
  }
}
