@use "sass:math";
/*----------------------
Get integer from unit size
/// @param {Unit} $n - Initial Unit
-----------------------*/
@function parseVal($n) {
  @return math.div($n, ($n * 0 + 1));
}


/*----------------------
Replace `$search` with `$replace` in `$string`
-----------------------*/
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

//  Function to create an optimized svg url
@function svg-url($svg){
  //
  //  Add missing namespace
  //
  @if not str-index($svg,xmlns) {
    $svg: str-replace($svg, '<svg','<svg xmlns="http://www.w3.org/2000/svg"');
  }
  //
  //  Chunk up string in order to avoid
  //  "stack level too deep" error
  //
  $encoded:'';
  $slice: 2000;
  $index: 0;
  $loops: ceil(math.div(str-length($svg), $slice));
  @for $i from 1 through $loops {
    $chunk: str-slice($svg, $index, $index + $slice - 1);
    //
    //   Encode
    //
    $chunk: str-replace($chunk, '"', '\'');
    $chunk: str-replace($chunk, '%', '%25');
    $chunk: str-replace($chunk, '#', '%23');
    $chunk: str-replace($chunk, '{', '%7B');
    $chunk: str-replace($chunk, '}', '%7D');
    $chunk: str-replace($chunk, '<', '%3C');
    $chunk: str-replace($chunk, '>', '%3E');

    //
    //    The maybe list
    //
    //    Keep size and compile time down
    //    ... only add on documented fail
    //
    //  $chunk: str-replace($chunk, '&', '%26');
    //  $chunk: str-replace($chunk, '|', '%7C');
    //  $chunk: str-replace($chunk, '[', '%5B');
    //  $chunk: str-replace($chunk, ']', '%5D');
    //  $chunk: str-replace($chunk, '^', '%5E');
    //  $chunk: str-replace($chunk, '`', '%60');
    //  $chunk: str-replace($chunk, ';', '%3B');
    //  $chunk: str-replace($chunk, '?', '%3F');
    //  $chunk: str-replace($chunk, ':', '%3A');
    //  $chunk: str-replace($chunk, '@', '%40');
    //  $chunk: str-replace($chunk, '=', '%3D');

    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
  }
  @return url("data:image/svg+xml,#{$encoded}");
}


// ---------------------------------------------------------
// str-explode
// Split a string by delimiter
//
// Required arguments:
// `$string` String
// `$delimiter` String *predefined*
//
// Example of use:
// str-explode('some-things-will-never-change', '-')
//
// @return List
// ---------------------------------------------------------
@function str-explode ($string, $delimiter: '') {
  @if type-of($string) != 'string' {
    @error 'The argument $string: `#{$string}` is of incorrect type: `#{type-of($string)}`. Type of `String` is required!';
  }
  @else if type-of($delimiter) != 'string' {
    @error 'The argument $string: `#{$string}` is of incorrect type: `#{type-of($string)}`. Type of `String` is required!';
  }

  $result: ();
  $running: true;

  @if str-length($delimiter) == 0 {
    @for $i from 1 through str-length($string) {
      $result: append($result, str-slice($string, $i, $i));
    }

    @return $result;
  }

  @while $running {
    $index: str-index($string, $delimiter);

    @if $index != null {
      $item: str-slice($string, 1, ($index - 1));
      $result: append($result, $item);
      $string: str-slice($string, ($index + str-length($delimiter)));
    }
    @else {
      $running: false;
    }
  }

  @return append($result, $string);
}

// ---------------------------------------------------------
// deep-map-check
// Check if nested map has a value by the keys
//
// Required arguments:
// `$map` Map
// `$keys` String(s)
//
// Example of use:
// deep-map-check($nested-map, 'key-1', 'key-2')
//
// @return Bool
// ---------------------------------------------------------
@function deep-map-check ($map, $keys...) {
  @if type-of($map) != 'map' {
    @error 'The argument $map: `#{$map}` is of incorrect type: `#{type-of($map)}`. Type of `Map` is required!';
  }

  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }

    $map: map-get($map, $key);
  }

  @return true;
}

// ---------------------------------------------------------
// deep-map-get
// Get a value from nested map by the keys
//
// Required arguments:
// `$map` Map
// `$keys` String(s)
//
// Example of use:
// deep-map-get($dfr-spacing, 'xs', 'y')
//
// @return Unspecified (it may return anything as a result)
// ---------------------------------------------------------
@function deep-map-get ($map, $keys...) {
  @if type-of($map) != 'map' {
    @error 'The argument $map: `#{$map}` is of incorrect type: `#{type-of($map)}`. Type of `Map` is required!';
  }

  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

// ---------------------------------------------------------
// get
// Search for any value contained within nested maps, by using
// dot notation as the key argument
//
// Required arguments:
// `$key` String
//
// Example of use:
// get('border.radius.xs')
//
// Dependencies:
// deep-map-check, deep-map-get, str-explode
//
// @return Anything
// ---------------------------------------------------------
@function get ($key, $map) {
  $keys: str-explode($key, '.');

  @if not deep-map-check($map, $keys...) {
    @error 'The argument $map: `#{$map}` doesn\'t has some of the $keys: `#{$keys}`!';
  }

  @return deep-map-get($map, $keys...);
}

@mixin object-fit($fit: fill, $position: null){
  -o-object-fit: $fit;
  object-fit: $fit;
  @if $position {
    -o-object-position: $position;
    object-position: $position;
    font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  } @else {
    font-family: 'object-fit: #{$fit}';
  }
}
