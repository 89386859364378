.cc-checkout-form {
  &__row {
    margin-bottom: 16px;
  }

  &__title {
    @include font-size(24px);
    line-height: 1.2;
    margin-bottom: 24px;
    color: #020202;
  }

  &__text {
    color: $color-gray-400;
    margin-bottom: 25px;

    & > a {
      font-weight: bold;
    }
  }
}

.cc-checkout-input {
  width: 100%;
  box-shadow: none;
  appearance: none;
  background-color: $color-gray-100;
  font-family: $font-open-sans;
  font-weight: normal;
  color: $color-gray-400;
  border: 1px solid $color-gray-300;
  transition: border-color 0.2s ease;
  border-radius: 10px;
  @include font-size(16px);
  line-height: 1;
  padding: 15px 25px;
  height: 54px;

  &:focus {
    box-shadow: none;
    outline: none;
    border-color: $color-gray-400;
  }

  &.cc--is-invalid {
    border-color: $color-red;
  }

  &::placeholder {
    color: rgba($color-gray-400, 0.5);
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: rgba($color-gray-400, 0.5);
  }

  &::-ms-input-placeholder {
    color: rgba($color-gray-400, 0.5);
  }
}
