h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto" !important;
}

h6 {
  font-weight: 200 !important;
}

.hr {
  display: flex;
  justify-content: center;
  width: 100%;

  hr {
    margin: 20px 0;
    border: 0;
    height: 1px;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0)
    );
    width: 80%;
  }
}

.shop-settings-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.shop-settings {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  max-width: 80%;
  align-items: start;
  justify-content: center;

  span {
    font-family: "Roboto" !important;
    font-weight: 200 !important;
    font-size: 14px;
  }

  @media (max-width: 1385px) {
    grid-template-columns: 1fr;
    text-align: center;
  }

  @media (max-width: 1385px) {
    text-align: center;
    max-width: 100%;

    .image,
    .shop-info,
    .shop-details {
      align-items: center;
    }

    .shop-settings-details {
      text-align: center;
    }
  }
}

.image {
  grid-column: 1 / span 1;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 200px;
    height: auto;
    object-fit: contain;
  }
}

.shop-info {
  grid-column: 2 / span 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 1385px) {
    padding: 20px;

    align-items: center;
  }

  h4,
  h5,
  h6 {
    margin: 5px 0;
  }

  .shop-settings-details {
    margin-top: 20px;

    h5 {
      font-weight: bold;
    }

    h6 {
      margin-left: 10px;
    }
  }
}

.shop-details {
  grid-column: 3 / span 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 1385px) {
    padding: 20px;
    align-items: center;
  }

  .shop-settings-details {
    margin-top: 20px;

    h5 {
      font-weight: bold;
    }

    h6 {
      margin-left: 10px;
    }
  }
}

@media (max-width: 1385px) {
  .image,
  .shop-info,
  .shop-details {
    grid-column: 1 / -1;
  }

  .shop-settings-details {
    text-align: left;
  }
}

@media (max-width: 1385px) {
  .shop-settings {
    grid-template-columns: 1fr;
  }

  .shop-settings-details {
    text-align: center;
    margin-left: 0;
  }

  .image {
    justify-content: center;
    align-items: center;
  }

  .shop-info,
  .shop-details {
    align-items: center;
  }

  h4,
  h5,
  h6 {
    margin-left: 0;

    text-align: center;
  }
}
