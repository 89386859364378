.cc-dropdown {
  position: relative;

  .cc-dropdown-item {
    cursor: pointer;
  }

  &.cc--top {
    .cc-dropdown-wrapper {
      bottom: 100%;
      margin-bottom: 38px;

      &:before {
        bottom: -12px;
        border-top: 12px #fff solid;
      }
    }
  }

  &.cc--bottom {
    .cc-dropdown-wrapper {
      top: 100%;
      margin-top: 18px;

      &:before {
        top: -12px;
        border-bottom: 12px #fff solid;
      }
    }
  }

  &-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    &:before {
      content: "";
      border-left: 7px transparent solid;
      border-right: 7px transparent solid;
      position: absolute;

      transform: translateX(-50%);
      z-index: 0;
      left: 50%;
    }
  }

  &-menu {
    background-color: $color-gray-100;
    border-radius: 10px;
    z-index: 1;
    width: 120px;
    position: relative;
    overflow: hidden;
    padding-top: 8px;
    padding-bottom: 8px;
    box-shadow: $shadow-dropdown;
  }

  &-item {
    & > a {
      @include font-size(14px);
      line-height: 1.2;
      color: $color-gray-400;
      display: block;
      padding: 8px 16px;
      text-decoration: none;
      display: flex;

      & > span {
        display: flex;
        align-items: center;
      }

      &:hover {
        background-color: $color-gray-200;
      }
    }

    &__icon {
      @include font-size(16px);
      color: $color-primary;
      margin-right: 10px;
    }
  }
}
