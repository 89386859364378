.cc-interaction-bar {
  position: fixed;
  z-index: 2;
  left: 50%;
  bottom: 105px;
  transform: translateX(-50%);
  border: 1px #fff solid;
  padding: 14px 20px;
  border-radius: 10px;
  background-color: rgba($color-blue-100, 0.3);
  backdrop-filter: blur(37px);
  flex-direction: column;
  align-items: center;

  &__action-btn {
    border-radius: 10px;
    @include font-size(18px);
    min-width: 42px;
    margin-bottom: 2px;

    & > span {
      padding: 4px;
    }
  }

  &__close {
    margin-left: auto;
  }

  &__item {
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;

    &__space_top {
      margin-top: 10px;
    }

    &.right {
      align-self: flex-end;
    }

    &.mb {
      margin-bottom: 1rem;
    }

    &.bottom-space {
      margin-bottom: 10px;
    }

    & > span {
      @include font-size(12px);
      line-height: 1.2;
      display: block;
      color: $color-gray-400;
      padding-top: 7px;
      user-select: none;
    }
  }

  &--level-2 {
    padding-top: 10px;
  }

  &--level-0 {
    padding-top: 0px;
  }
}
