.dropzone-wrapper {
  align-items: center;
  min-height: 100px;
  min-width: 300px;
}

.image {
  width: 120px;
  height: 72px;
  position: absolute;
  z-index: 9999;

  & > img {
    border: 1px $color-primary solid;
  }
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
