.cc-project-sidebar {
  position: fixed;
  z-index: 2;
  right: 0;
  top: 0;
  bottom: 0;
  border: 1px #fff solid;
  display: flex;
  flex-direction: column;

  &--group {
    display: flex;
    justify-content: space-around;
  }

  &-w-smallShort {
    @extend .cc-project-sidebar;
    width: 100%;
    height: 62%;
    top: 10%;
  }

  &-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px #fff solid;
    margin-right: 3px;

    &.green {
      background-color: $color-green;
    }

    &.red {
      background-color: $color-red;
    }
  }

  &-w-normal {
    @extend .cc-project-sidebar;
    width: 410px;
  }
  &-w-normalShort {
    @extend .cc-project-sidebar;
    width: 90%;
    height: 72%;
    right: 50%;
    top: 15%;
    transform: translate(50%, 0%);
  }
  &-w-lg {
    @extend .cc-project-sidebar;
    width: 550px;
  }
  &-w-lgShort {
    @extend .cc-project-sidebar;
    width: 550px;
    height: 90%;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color-blue-100, 0.1);
    backdrop-filter: blur(37px);
  }

  &__toggle-btn {
    position: fixed !important;
    top: 30px;
    right: 30px;
    z-index: 1;
    border-radius: 10px;
    min-width: 120px;
    box-shadow: $shadow-dropdown;

    & > span {
      padding: 13px 15px;
    }

    & .cc-icon {
      @include font-size(20px);
      margin-right: 10px;
    }
  }

  &__heading {
    p {
      &:nth-child(1) {
        @include font-size(20px);
        line-height: 1.2;
        user-select: none;
        margin-bottom: 6px;
      }
      &:nth-child(2) {
        font-size: 1rem;
        font-weight: 400;
        color: $color-gray-300;
      }
    }
  }

  &__header {
    padding: 30px;
    background-color: rgba($color-gray-100, 0.8);
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;
    flex-shrink: 0;
  }

  &__close-btn {
    @include font-size(20px);
    flex-shrink: 0;
    margin-left: 10px;
    border-radius: 50%;

    & > span {
      padding: 5px;
    }
  }

  &__wrapper {
    position: relative;
    z-index: 1;
    flex-grow: 1;
    overflow-y: auto;
  }

  &__np {
    padding: 0;
  }
  &__p {
    padding: 30px;
  }
}

.cc-basket-sidebar__toggle-btn {
  position: fixed !important;
  top: 100px;
  right: 30px;
  z-index: 1;
  border-radius: 10px;
  min-width: 120px;
  box-shadow: $shadow-dropdown;

  & > span {
    padding: 13px 15px;
  }

  & .cc-icon {
    @include font-size(20px);
    margin-right: 10px;
  }
}

.cc-basket__label {
  background-color: $color-red;
  @include font-size(10px);
  line-height: 0.8;
  min-width: 16px;
  text-align: center;
  color: #fff;
  padding: 3px 4px;
  height: 16px;
  border-radius: 15px;
  position: absolute;
  top: 2px;
  left: 34px;
}
