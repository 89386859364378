.cc-message {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 0 10px;
  margin: 7px 0;

  &-owner {
    @extend .cc-message;
    flex-direction: row-reverse;
    justify-self: flex-end;
    .message-container {
      background-color: $color-primary;
      margin-right: 16px;
      .message-svg {
        right: -10px;
        color: $color-primary;
      }
      .message-content {
        color: $color-white;
      }
      .name-date-container {
        p {
          color: $color-white;
        }
      }
    }
  }
  &-n-owner {
    @extend .cc-message;
    flex-direction: row;
    justify-self: flex-start;
    .message-container {
      background-color: $color-white;
      margin-left: 16px;
      .message-svg {
        left: -10px;
        color: $color-white;
      }
      .message-content {
        color: $color-gray-400;
      }
      .name-date-container {
        p {
          color: $color-gray-300;
        }
      }
    }
  }

  .avatar-container {
    width: 40px;
    height: 40px;
  }

  .message-container {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 10px;
    padding: 12px;

    .message-svg {
      height: 15px;
      width: 30px;
      position: absolute;
      top: 0px;
    }

    .name-date-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      p {
        margin: 0;
        font-size: 12px;

        &:nth-child(1) {
          font-weight: bold;
        }
        &:nth-child(2) {
          margin: 0 5px;
        }
      }
    }
    .message-content {
      p {
        margin: 3px 0;
        font-size: 13px;
      }
    }
  }
}
