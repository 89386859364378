.cc-checkout-layout {
  &__container {
    max-width: 1080px;
    margin: 0 auto;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 120px;

    &.cc--grid {
      display: flex;

      &:before {
        content: "";
        position: fixed;
        background-color: $color-gray-100;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 0;
        width: 44%;
        box-shadow: 0 3px 20px rgba($color-gray-400, 0.2);
      }
    }
  }

  &__card {
    border-radius: 10px;
    background-color: $color-gray-100;
    box-shadow: 0 3px 20px rgba($color-gray-400, 0.2);
    padding: 20px 30px 40px;
    margin-bottom: 30px;
  }

  &__heading {
    font-family: $font-open-sans;
    margin-bottom: 30px;
    color: $color-gray-400;
  }

  &__action {
    &-list {
      display: flex;
      justify-content: center;
      margin-left: -15px;
      margin-right: -15px;
    }

    &-item {
      padding-left: 15px;
      padding-right: 15px;
      width: 50%;
    }
  }
}

.cc-checkout-action {
  &__card {
    padding: 25px 30px 30px;
    border-radius: 10px;

    &.cc--dark {
      background-color: $color-gray-400;
      box-shadow: 0 3px 10px rgba($color-gray-400, 0.6);

      .cc-checkout-action__text {
        color: #fff;
      }
    }

    &.cc--light {
      background-color: $color-gray-100;
      box-shadow: 0 3px 10px rgba($color-gray-400, 0.2);

      .cc-checkout-action__text {
        color: #020202;
      }
    }
  }

  &__text {
    margin-bottom: 15px;
    @include font-preset("body-1");
  }
}
